/**
 * Global Styling for Third-Party Radio Button Container
 *
 * This file defines the global styling rules for a radio button container with
 * third-party integration using the SCSS syntax. It includes references to variables,
 * mixins, and Angular Material.
 */

// Importing variables, Angular Material, mixins, and Sass map
@use '../../styles/variables' as v;
@use '@angular/material' as mat;
@use 'sass:map';
@use '../../styles/mixins' as m;

.tp-radio-container {
    display: block;
    flex: 1;

    .mat-mdc-radio-group {
        flex-direction: column;
        padding: 0 0 1.2rem;
        display: block;
        height: 100%;

        .service-info-container {
            border-bottom: 1px solid v.$dsb-neutral-400;
        }

        .mat-mdc-radio-button {
            display: flex;
            font-family: v.$dsb-font;
            font-style: normal;
            font-weight: 400;
            font-size: 1.6rem;
            line-height: 2.1rem;
            height: 4.8rem;
            color: v.$dsb-neutral-900;

            .mdc-form-field {
                width: 100%;

                .mdc-radio {
                    padding: unset;

                    /* stylelint-disable selector-class-pattern */
                    .mdc-radio__native-control {
                        top: -1rem;
                        left: -1rem;
                    }
                    /* stylelint-enable selector-class-pattern */
                }

                .mdc-label {
                    padding-left: 1.2rem;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    cursor: pointer;
                    width: 100%;
                    height: 100%;

                    .price-container {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: flex-end;
                        padding-right: 0.8rem;

                        .from-word {
                            font-family: v.$dsb-font;
                            font-style: italic;
                            font-weight: 500;
                            font-size: 1rem;
                            line-height: 1rem;
                            color: v.$dsb-neutral-600;
                            margin-bottom: 0.4rem;
                        }

                        .service-price {
                            font-family: v.$dsb-font;
                            font-weight: 500;
                            font-size: 1.8rem;
                            line-height: 2rem;
                            color: mat.get-color-from-palette(v.$theme-primary-palette, 500);
                        }
                    }
                }
            }

            /* stylelint-disable selector-class-pattern */
            &.mat-accent.mat-mdc-radio-checked .mdc-radio__outer-circle {
                border-color: mat.get-color-from-palette(v.$theme-primary-palette, 500) !important;
            }

            &.mat-accent .mdc-radio__inner-circle {
                color: mat.get-color-from-palette(v.$theme-primary-palette, 500);
                background-color: mat.get-color-from-palette(v.$theme-primary-palette, 500) !important;
            }
            /* stylelint-enable selector-class-pattern */

            &.mat-accent .mat-radio-ripple .mat-ripple-element {
                background-color: mat.get-color-from-palette(v.$theme-primary-palette, 500) !important;
            }
        }

        .step-service-deposit {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 0.8rem 1.2rem;
            gap: 0.8rem;
            background: v.$dsb-deposit-background-color;
            border-left: 0.4rem solid v.$dsb-deposit-border-left-color;
            font-family: v.$dsb-font;
            font-style: normal;
            font-weight: 400;
            font-size: 1.4rem;
            line-height: 2.4rem;
            margin-bottom: 1.2rem;

            .bold {
                font-weight: 700;
                -webkit-font-smoothing: antialiased;
            }
        }

        .info-icon {
            display: flex;
            position: relative;
            align-self: flex-start;
            width: 1.67rem !important;
            height: 1.67rem;
            margin-top: 1rem;
            top: -0.5rem;
        }
    }
}

@include m.respond-to(tablet) {
    .mat-mdc-radio-group {
        .mat-mdc-radio-button {
            font-size: 2rem;
            height: 6.3rem;
        }
    }
}
