/**
 * Global Styling for Material Button Toggle
 *
 * This file defines the global styling rules for Material Design button toggles
 * using the SCSS syntax. It includes references to variables, mixins, and Angular
 * Material's color palette.
 */

// Importing variables and mixins
@use '../../styles/variables' as v;
@use '@angular/material' as mat;
@use 'sass:map';
@use '../../styles/mixins' as m;

.mat-button-toggle-checked {
    border: 0.1rem solid mat.get-color-from-palette(v.$theme-primary-palette, 500) !important;
    color: mat.get-color-from-palette(v.$theme-primary-palette, 500) !important;
    background: mat.get-color-from-palette(v.$theme-primary-palette, 50) !important;

    .week-day,
    .week-date {
        color: mat.get-color-from-palette(v.$theme-primary-palette, 500) !important;
    }
}

.mat-button-toggle:hover {
    background: mat.get-color-from-palette(v.$theme-primary-palette, 100) !important;

    .mat-button-toggle-focus-overlay {
        background-color: unset;
    }
}

.mat-button-toggle-ripple {
    border-radius: 1.6rem;
}
