/* You can add global styles to this file, and also import other style files */
@use './styles/variables' as v;
@use './styles/mixins' as m;
@use '@angular/material' as mat;

/* Material override imports */
@import './styles/material-override/mat-button-toggle';
@import './styles/material-override/mat-button';
@import './styles/material-override/mat-calendar';
@import './styles/material-override/mat-checkbox';
@import './styles/material-override/mat-form-field';
@import './styles/material-override/mat-horizontal-stepper';
@import './styles/material-override/mat-radio-button';
@import './styles/material-override/mat-select';
@import './styles/material-override/mat-bottom-sheet';

@include mat.core;

$dynamic-theming-demo-theme: mat.define-light-theme(
    (
        color: (
            primary: v.$theme-primary-palette,
            accent: v.$theme-accent-palette,
            warn: v.$theme-warn-palette,
        ),
    )
);

/* Include theme styles for core and each component used in your app.
   Alternatively, you can import and @include the theme mixins for each component
   that you are using. */
@include mat.all-component-themes($dynamic-theming-demo-theme);

*,
*::before,
*::after {
    box-sizing: inherit;
}

html {
    box-sizing: border-box;
    font-size: 62.5%;
}

body {
    font-family: v.$dsb-font;
    margin: 0;
    font-weight: normal;
}

h1,
h2,
h3 {
    margin: 0;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    box-shadow: 0 0 0 3rem white inset !important;
}

.mat-bottom-sheet-container {
    height: 100%;
    flex-shrink: 1;
}

.confirmation-component-container {
    display: flex;
    overflow-x: scroll;
    width: 100%;
}

.content-component-container,
.login-component-container,
.create-account-component-container,
.add-pet-component-container {
    width: 100%;
}

.disable-click {
    cursor: not-allowed;
    pointer-events: none;
    text-decoration: none;
}

@include m.respond-to(desktop) {
    /* custom scrollbar */
    ::-webkit-scrollbar {
        width: 2rem;
    }

    ::-webkit-scrollbar-track {
        background-color: transparent;
    }

    ::-webkit-scrollbar-thumb {
        background-color: v.$dsb-neutral-300;
        border-radius: 2rem;
        border: 0.6rem solid transparent;
        background-clip: content-box;
    }

    ::-webkit-scrollbar-thumb:hover {
        background-color: v.$dsb-neutral-400;
    }
}

.grecaptcha-badge {
    width: 70px !important;
    overflow: hidden !important;
    transition: all 0.3s ease !important;
    left: 4px !important;
    bottom: 1px !important;
    transform: scale(0.77);
    // stylelint-disable-next-line
    transform-origin: 0 0;
}

.grecaptcha-badge:hover {
    width: 256px !important;
}

.mat-mdc-snack-bar-label.mdc-snackbar__label {
    font-size: 14px !important;
}
