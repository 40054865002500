/**
 * Global Styling for Material Design Bottom Sheet
 *
 * This file contains global styling rules for Material Design Bottom Sheets using the SCSS syntax.
 * It includes references to variables, mixins, and Angular Material's color palette.
 */

.mat-bottom-sheet-container {
    justify-content: center;
    height: 44.4rem;
    flex-shrink: 0;
    border-radius: 1.6rem 1.6rem 0 0 !important;
    padding: 1.6rem !important;
}
