@use '@angular/material' as mat;

// *** Global fonts   *** //
$dsb-font: 'DM Sans', arial, sans-serif;

// *** Global variables   *** //
$dsb-titlebar-height: 6rem;
$dsb-titlebar-height-tablet: 7.8rem;
$dsb-navigation-button-height: 6.7rem;
$dsb-navigation-button-height-tablet: 9.2rem;
$dsb-input-border-radius: 4px;

// *** Global colors   *** //
$dsb-invalid-icon-color: #fec90e;
$dsb-deposit-background-color: #faf6e4;
$dsb-deposit-border-left-color: #e7a23c;
$dsb-required-star-color: #f75f55;
$dsb-red-text-color: #e60202;
$dsb-blue-text-color: #3c6ce7;
$dsb-white-color: #fff;
$dsb-neutral-200: #eee;
$dsb-neutral-300: #e0e0e0;
$dsb-neutral-400: #bdbdbd;
$dsb-neutral-500: #a9a9a9;
$dsb-neutral-600: #707070;
$dsb-neutral-700: #616161;
$dsb-neutral-800: #424242;
$dsb-neutral-900: #212121;
$dsb-black-color: #222;

// *** Universal variables *** //
$dsb-gap-8: 0.8rem;
$dsb-gap-12: 1.2rem;
$dsb-gap-16: 1.6rem;

// *** Theme palette ***//
$theme-primary-color-map: (
    50: var(--theme-primary-color-50),
    100: var(--theme-primary-color-100),
    200: var(--theme-primary-color-200),
    300: var(--theme-primary-color-300),
    400: var(--theme-primary-color-400),
    500: var(--theme-primary-color-500),
    600: var(--theme-primary-color-600),
    700: var(--theme-primary-color-700),
    800: var(--theme-primary-color-800),
    900: var(--theme-primary-color-900),
    a100: var(--theme-primary-color-a100),
    a200: var(--theme-primary-color-a200),
    a400: var(--theme-primary-color-a400),
    a700: var(--theme-primary-color-a700),
    contrast: (
        50: var(--theme-primary-color-contrast-50),
        100: var(--theme-primary-color-contrast-100),
        200: var(--theme-primary-color-contrast-200),
        300: var(--theme-primary-color-contrast-300),
        400: var(--theme-primary-color-contrast-400),
        500: var(--theme-primary-color-contrast-500),
        600: var(--theme-primary-color-contrast-600),
        700: var(--theme-primary-color-contrast-700),
        800: var(--theme-primary-color-contrast-800),
        900: var(--theme-primary-color-contrast-900),
        a100: var(--theme-primary-color-contrast-a100),
        a200: var(--theme-primary-color-contrast-a200),
        a400: var(--theme-primary-color-contrast-a400),
        a700: var(--theme-primary-color-contrast-a700),
    ),
);
$theme-accent-color-map: (
    50: var(--theme-accent-color-50),
    100: var(--theme-accent-color-100),
    200: var(--theme-accent-color-200),
    300: var(--theme-accent-color-300),
    400: var(--theme-accent-color-400),
    500: var(--theme-accent-color-500),
    600: var(--theme-accent-color-600),
    700: var(--theme-accent-color-700),
    800: var(--theme-accent-color-800),
    900: var(--theme-accent-color-900),
    a100: var(--theme-accent-color-a100),
    a200: var(--theme-accent-color-a200),
    a400: var(--theme-accent-color-a400),
    a700: var(--theme-accent-color-a700),
    contrast: (
        50: var(--theme-accent-color-contrast-50),
        100: var(--theme-accent-color-contrast-100),
        200: var(--theme-accent-color-contrast-200),
        300: var(--theme-accent-color-contrast-300),
        400: var(--theme-accent-color-contrast-400),
        500: var(--theme-accent-color-contrast-500),
        600: var(--theme-accent-color-contrast-600),
        700: var(--theme-accent-color-contrast-700),
        800: var(--theme-accent-color-contrast-800),
        900: var(--theme-accent-color-contrast-900),
        a100: var(--theme-accent-color-contrast-a100),
        a200: var(--theme-accent-color-contrast-a200),
        a400: var(--theme-accent-color-contrast-a400),
        a700: var(--theme-accent-color-contrast-a700),
    ),
);
$theme-warn-color-map: (
    50: var(--theme-warn-color-50),
    100: var(--theme-warn-color-100),
    200: var(--theme-warn-color-200),
    300: var(--theme-warn-color-300),
    400: var(--theme-warn-color-400),
    500: var(--theme-warn-color-500),
    600: var(--theme-warn-color-600),
    700: var(--theme-warn-color-700),
    800: var(--theme-warn-color-800),
    900: var(--theme-warn-color-900),
    a100: var(--theme-warn-color-a100),
    a200: var(--theme-warn-color-a200),
    a400: var(--theme-warn-color-a400),
    a700: var(--theme-warn-color-a700),
    contrast: (
        50: var(--theme-warn-color-contrast-50),
        100: var(--theme-warn-color-contrast-100),
        200: var(--theme-warn-color-contrast-200),
        300: var(--theme-warn-color-contrast-300),
        400: var(--theme-warn-color-contrast-400),
        500: var(--theme-warn-color-contrast-500),
        600: var(--theme-warn-color-contrast-600),
        700: var(--theme-warn-color-contrast-700),
        800: var(--theme-warn-color-contrast-800),
        900: var(--theme-warn-color-contrast-900),
        a100: var(--theme-warn-color-contrast-a100),
        a200: var(--theme-warn-color-contrast-a200),
        a400: var(--theme-warn-color-contrast-a400),
        a700: var(--theme-warn-color-contrast-a700),
    ),
);
$theme-primary-palette: mat.define-palette($theme-primary-color-map);
$theme-accent-palette: mat.define-palette($theme-accent-color-map);
$theme-warn-palette: mat.define-palette($theme-warn-color-map);
