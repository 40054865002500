/**
 * Global Styling for Material Design Form Fields and Selects
 *
 * This file defines the global styling rules for Material Design form fields and selects
 * using the SCSS syntax. It includes references to variables, mixins, and Angular Material.
 */

// Importing variables, Angular Material, mixins, and Sass map
@use '../../styles/variables' as v;
@use '@angular/material' as mat;
@use 'sass:map';
@use '../../styles/mixins' as m;

.mat-mdc-form-field {
    .mat-mdc-text-field-wrapper {
        .mat-mdc-form-field-flex {
            .mat-mdc-form-field-infix {
                .mat-mdc-select {
                    font-family: v.$dsb-font;
                    font-size: 1.6rem;

                    .mat-mdc-select-trigger {
                        .mat-mdc-select-arrow-wrapper {
                            display: none;
                        }
                    }
                }

                .dropdown-button {
                    display: flex;

                    & > path {
                        fill: mat.get-color-from-palette(v.$theme-primary-palette, 500);
                    }
                }
            }
        }
    }
}

/* stylelint-disable selector-class-pattern */
.mat-mdc-option.mdc-list-item {
    .mdc-list-item__primary-text {
        font-size: 1.8rem;
    }
}
/* stylelint-disable selector-not-notation */
.mat-mdc-select-panel .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled):not(.mat-mdc-option-multiple) {
    background-color: mat.get-color-from-palette(v.$theme-primary-palette, 50);
}
/* stylelint-enable selector-not-notation */
/* stylelint-enable selector-class-pattern */

@include m.respond-to(tablet) {
    .mat-mdc-form-field {
        .mat-mdc-text-field-wrapper {
            .mat-mdc-form-field-flex {
                .mat-mdc-form-field-infix {
                    .mat-mdc-select {
                        font-size: 1.8rem;
                    }

                    .dropdown-button {
                        width: 2rem;
                        height: 2rem;
                    }
                }
            }
        }
    }
}
