/**
 * Global Styling for Material Design Buttons
 *
 * This file contains global styling rules for Material Design buttons using the SCSS syntax.
 * It includes references to variables, mixins, and Angular Material's color palette.
 */

// Importing variables and Angular Material
@use '../../styles/variables' as v;
@use '@angular/material' as mat;

.mdc-button.mat-unthemed.mat-mdc-button-base {
    height: 4rem;
    font-family: v.$dsb-font;

    /* stylelint-disable selector-class-pattern */
    .mdc-button__label {
        display: flex;
        align-items: center;
    }
    /* stylelint-enable selector-class-pattern */

    &.mat-mdc-button {
        background: mat.get-color-from-palette(v.$theme-primary-palette, 500);
        color: mat.get-color-from-palette(v.$theme-primary-palette, '500-contrast');

        &:disabled {
            background: mat.get-color-from-palette(v.$theme-primary-palette, 200);
            color: mat.get-color-from-palette(v.$theme-primary-palette, '200-contrast');

            /* stylelint-disable selector-class-pattern */
            .mdc-button__label {
                .forward-button > path {
                    fill: mat.get-color-from-palette(v.$theme-primary-palette, '200-contrast');
                }
            }
            /* stylelint-enable selector-class-pattern */
        }

        /* stylelint-disable selector-class-pattern */
        .mdc-button__label {
            gap: 0.6rem;
            color: mat.get-color-from-palette(v.$theme-primary-palette, '500-contrast');

            svg.forward-button-arrow {
                height: 1.8rem;
            }
        }
        /* stylelint-enable selector-class-pattern */

        .mat-mdc-progress-spinner circle {
            stroke: mat.get-color-from-palette(v.$theme-primary-palette, '500-contrast');
        }
    }

    /* stylelint-disable selector-class-pattern */
    &.mdc-button--outlined.mat-mdc-outlined-button {
        font-weight: 500;
        color: v.$dsb-neutral-700;
        background: v.$dsb-white-color;
        border: 1px solid v.$dsb-neutral-500;
    }
    /* stylelint-enable selector-class-pattern */
}
