/**
 * Global Styling for Material Design Form Fields
 *
 * This file defines the global styling rules for Material Design form fields and text fields
 * using the SCSS syntax. It includes references to variables, mixins, and Angular Material.
 */

// Importing variables, Angular Material, mixins, and Sass map
@use '../../styles/variables' as v;
@use '@angular/material' as mat;
@use 'sass:map';
@use '../../styles/mixins' as m;

.mat-mdc-form-field {
    all: unset;
    font-family: v.$dsb-font;

    .mat-mdc-text-field-wrapper {
        all: unset;

        .mat-mdc-form-field-focus-overlay {
            all: unset;
        }

        .mat-mdc-form-field-flex {
            display: flex;
            padding: 0;
            border: 1px solid v.$dsb-neutral-700;
            border-radius: v.$dsb-input-border-radius;
            height: 4rem;
            align-items: center;
            background-color: v.$dsb-white-color;

            &:focus-within {
                border: 0.15rem solid mat.get-color-from-palette(v.$theme-primary-palette, 500);
                border-radius: v.$dsb-input-border-radius;
                caret-color: mat.get-color-from-palette(v.$theme-primary-palette, 500);
            }

            .mat-mdc-form-field-infix {
                display: flex;
                align-items: center;
                padding: 1rem;
                border: 0;

                input {
                    font-size: 1.6rem;
                    height: 3.6rem;
                }
            }
        }

        .mdc-line-ripple {
            height: 0 !important;
            display: none !important;
        }
    }

    .mat-mdc-form-field-subscript-wrapper {
        display: none;
    }
}

@include m.respond-to(tablet) {
    .mat-mdc-form-field {
        .mat-mdc-text-field-wrapper {
            .mat-mdc-form-field-flex {
                height: 5.6rem;

                .mat-mdc-form-field-infix {
                    input {
                        font-size: 1.8rem;
                    }
                }
            }
        }
    }
}
