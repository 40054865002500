/**
 * Global Styling for Material Design Checkboxes
 *
 * This file defines the global styling rules for Material Design checkboxes using the SCSS syntax.
 * It includes references to variables, mixins, and Angular Material.
 */

// Importing variables, Angular Material, mixins, and Sass map
@use '../../styles/variables' as v;
@use '@angular/material' as mat;
@use 'sass:map';
@use '../../styles/mixins' as m;

/* stylelint-disable selector-class-pattern */
.mat-mdc-checkbox-checked.mat-accent .mdc-checkbox__background {
    background-color: mat.get-color-from-palette(v.$theme-primary-palette, 500) !important;
    border: unset;
}
/* stylelint-enable selector-class-pattern */

.mat-mdc-checkbox {
    @include m.transparent-color-mixin(80%, v.$dsb-neutral-900);

    font-size: 1.6rem;
    font-family: v.$dsb-font;
    font-style: normal;
    font-weight: 400;

    .mdc-form-field {
        width: 100%;

        &:first-child {
            margin-bottom: -1rem;
        }

        .mdc-checkbox {
            margin-left: calc((var(--mdc-checkbox-state-layer-size) - 1.8rem) / -2);

            /* stylelint-disable selector-class-pattern */
            .mdc-checkbox__background {
                border-radius: 0.4rem;

                .mdc-checkbox__checkmark {
                    height: 1.4rem;
                    width: 1.4rem;
                    margin: 2px;

                    path {
                        color: mat.get-color-from-palette(v.$theme-primary-palette, '500-contrast');
                    }
                }
            }

            .mdc-checkbox__native-control {
                all: unset;
                display: flex;
                width: 1.8rem;
                height: 1.8rem;
            }

            .mat-mdc-checkbox-touch-target,
            .mdc-checkbox__ripple,
            .mat-ripple.mat-mdc-checkbox-ripple {
                all: unset;
            }
            /* stylelint-enable selector-class-pattern */
        }
    }
}
